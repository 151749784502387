import axios from "axios"
import { error } from "jquery"
import { del, get, post, postFile, put, postData, postFile1} from "./api_helper"
import * as url from "./url_helper"
import {ToastHelper} from './alerts/alertHelper';
// Gets the logged in user data from local session
const getLoggedInUser = () => {
    const user = localStorage.getItem("authUser")
    if (user) return JSON.parse(user)
        return null
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null
}
//reset pasword
export const userResetPasw = user => post(url.RESET_PASSW, user)
export const changePassw = data => post(url.CHANGE_PASSW, data)
export const sendConfirmation = data => post(url.SEND_CONFIRMATION, data)

// Login Method

export const postLoginToken = (data) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    return axios
    .post(process.env.REACT_APP_BACKEND_API_URL + url.POST_LOGIN_TOKEN, data, { headers: headers })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
          throw response.data
    })
    .catch(err => {
        console.log('err', err.response.data);
        var message = ''
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 404:
                    message = "Lo sentimos el recurso solicitado no existe"
                    break
                case 422:
                    message = err.response.data.message
                    break
                case 500:
                    message = "Lo sentimos! algo ha salido mal, por favor contacta a nuestro equipo de soporte."
                    break
                case 401:
                    message = "Credenciales inválidas"
                    break
                default:
                    message = err.response?.data?.message || err.message
                    break
            }
            ToastHelper(message,'error')
        } else {
            message = err.message
        }
        throw message
    })
}

// ME controller
export const getRouteConfigs = () => get(`${url.ME}/route-configs`);
export const postRouteConfig = (data) => post(`${url.ME}/route-configs`,data);

// get Users
export const GET_ME = "/user";
export const getUsers = (config) => get(url.RESOURCE_USERS, config);
export const postUser = user => post(url.RESOURCE_USERS, user)
export const putUser = user => put(`${url.RESOURCE_USERS}/${user.id}`, user)
export const assignHostsToUser = (id, data) => post(`${url.RESOURCE_USERS}/assign-hosts/${id}`, data)
export const deleteUser = id => del(`${url.RESOURCE_USERS}/${id}`)
export const getProfiles = (id) => get(`${url.RESOURCE_USERS}/${id}`);


export const postSalidasImportFile = (file, config={}) => postFile1(`${url.POST_SALIDAS_IMPORT_FILE}`, file, {
    ...config,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
})

export const postFileProfile = (id,data) => postFile(`${url.FILE_PROFILE_USER}/${id}`, data)
export const activeUsers = (data) => post(url.ACTIVE_USER,data);
export const changePasswordUser = (id,data) => put(`${url.CHANGE_PASSWORD_USER}/${id}`, data)
//catalogs
export const getProfilescat = ()  => get(url.GET_PROFILES);
//condiguration
export const getConfigurations = (config) => get(url.RESOURCE_CONFIGURATIONS,config);
export const postFileFileConfiguration = configuration => postData(url.RESOURCE_CONFIGURATIONS, configuration)
export const getConfigurationsSelects = () => get(url.SELECTS_CONFIGURATIONS);
export const getConfiguration = () => get(url.GET_ACTIVE_CONFIGURATION);
export const deleteConfiguration = id => del(`${url.RESOURCE_CONFIGURATIONS}/${id}`)
//company
export const getCompanies = config => get(url.RESOURCE_COMPANIES,config);
export const postCompany = company => post(url.RESOURCE_COMPANIES,company);
export const putCompany = company => put(`${url.RESOURCE_COMPANIES}/${company.id}`,company);
//plan
export const getPlans = config => get(url.RESOURCE_PLANS,config);
export const postPlan = company => post(url.RESOURCE_PLANS,company);
export const putPlan = company => put(`${url.RESOURCE_PLANS}/${company.id}`,company);
export const delPlan = id => del(`${url.RESOURCE_PLANS}/${id}`);
//profiles - permissions
export const getProfilesPermissions = (config) => get(url.RESOURCE_PROFILES,config);
export const postProfile = profile => post(url.RESOURCE_PROFILES,profile);
export const putProfile = profile => put(`${url.RESOURCE_PROFILES}/${profile.id}`,profile);
export const getPermissions = (id,config) => get(`${url.RESOURCE_PERMISSIONS}/${id}`,config);
export const getSelectsPermissions = (id) => get(`${url.PERMISSIONS_SELECT}/${id}`);
export const postPermission = permission => post(url.RESOURCE_PERMISSIONS,permission);
export const putPermission = permission => put(`${url.RESOURCE_PERMISSIONS}/${permission.id}`,permission);
export const deletePermission = id => del(`${url.RESOURCE_PERMISSIONS}/${id}`)
export const usersProfile = (id,company_id) => get(`${url.USERS_PROFILE}/${id}/${company_id}`)
/**Catalogos de sistema**/
//routes
export const resourcesRoutes = (type,data={},id=null) => typeResource(url.RESOURCE_ROUTE,type,data,id)
export const getRouteSelects = (id=false) => get(`${url.GET_ROUTE_SELECTS}/${id}`);
export const activeRoutes = (data) => post(url.ACTIVE_ROUTES,data);

//Configuracion de rutas
export const resourcesConfigurationRoute = (type,data={},id=null) => typeResource(url.RESOURCE_CONFIGURATION_ROUTE,type,data,id)
export const activeConfiguration = (data) => post(url.ACTIVE_CONFIGURATION_ROUTE,data);
export const activeLockConfiguration = (data) => post(url.ACTIVE_LOCK_CONFIGURATION_ROUTE,data);
//units
export const resourcesUnits = (type,data={},id=null) => typeResource(url.RESOURCE_UNIT,type,data,id)
export const getUnitsWithOperators = (config) => get(`${url.RESOURCE_UNIT}/select`,config);
export const adminUsers = () => get(`${url.GET_USERS_SELECTS}`);
//ramales
export const resourcesBranch = (type,data={},id=null) => typeResource(url.RESOURCE_BRANCH,type,data,id)
export const activeBranch = (data) => post(url.ACTIVE_BRANCH,data);
export const duplicateBranch = (data) => post(`${url.DUPLICATE_BRANCH}/${data.id}`,data);
export const associateBranchUnits = (id, data) => post(`${url.ASSOCIATE_BRANCH_UNITS}/${id}`,data);
//geocerca
export const resourcesGeofence = (type,data={},id=null) => typeResource(url.RESOURCE_GEOFENCE,type,data,id)
//ramal_geocercas
export const resourcesBranchGeo = (type,data={},id=null) => typeResource(url.RESOURCE_BRANCH_GEO,type,data,id)
//Operadores
export const resourcesOperators = (type,data={},id=null) => typeResource(url.RESOURCE_OPERATORS,type,data,id)
export const observationSave = (data) => post(url.SAVE_OBSERVATION,data);
export const getOperatorsUnitSelects = () => get(`${url.GET_OPERATORS_UNITS}`);
//Concesionarios
export const resourcesConcesionarios = (type,data={},id=null) => typeResource(url.RESOURCE_CONCESIONARIOS,type,data,id)

//salidas
export const resourcesSalidas = (type,data={},id=null) => typeResource(url.RESOURCE_SALIDAS,type,data,id)
export const resourcesSalidasConfigs = (type,data={},id=null) => typeResource(url.RESOURCE_SALIDAS_CONFIGS,type,data,id)
export const returnStore = (data) => post(url.RETURN_STORE,data);
export const getSalidasTablero = (url_route,data) => get(`${url.GET_SALIDAS_TABLERO}/${url_route}`,data);
export const postPagoAdministrativo = (data) => post(url.POST_PAGO_ADMINISTRATIVO,data);
export const getSalidasFilters = () => get(`${url.BASE_SALIDAS}/filters`);
export const getSalidasDataPath = (sid, config) => get(`${url.BASE_SALIDAS}/getDataPath/${sid}`, config);
export const getSalidasCheckEvents = (id) => get(`${url.BASE_SALIDAS}/${id}/checkEvents`);
export const getNextTimeSalida = (branch_id) => get(`${url.BASE_SALIDAS}/next/${branch_id}`);
export const getSalidaReport = (name, config={}) => get(`${url.BASE_SALIDAS}/report/${name}`,config);
export const deleteSalidasAll = (date, config={}) => del(`${url.BASE_SALIDAS}/day/${date}`,config);


//imprevistos
export const resourcesImprevistos = (type,data={},id=null) => typeResource(url.RESOURCE_IMPREVISTOS,type,data,id)
export const imprevistosSelects = () => get(`${url.GET_IMPREVISTOS_SELECTS}`);
//Attendances
export const resourcesAttendances = (type,data={},id=null) => typeResource(url.RESOURCE_ATTENDANCE,type,data,id)
export const resourcesAttendancesConcesionario = (type,data={},id=null) => typeResource(url.RESOURCE_ATTENDANCE_CONCESIONARIO,type,data,id)

// Deposits
export const resourcesDeposits = (type,data={},id=null) => typeResource(url.RESOURCE_DEPOSITS,type,data,id)
export const getUnitsDataDeposit = (config) => get(`${url.RESOURCE_DEPOSITS}/detail`,config);

// Payroll
export const resourcesPayroll = (type,data={},id=null) => typeResource(url.RESOURCE_PAYROLL,type,data,id)
export const resourcesServers = (type,data={},id=null) => typeResource(url.RESOURCE_SERVERS,type,data,id)
export const getServers = () => get(url.RESOURCE_SERVERS + '/jlist')
export const restartServer = (id) => get(`${url.RESOURCE_SERVERS}/restart/${id}`);
export const forceRestartServer = (id) => get(`${url.RESOURCE_SERVERS}/force-restart/${id}`);
export const getServerLogs = (id) => get(`${url.RESOURCE_SERVERS}/logs/${id}`);

//papeletas
export const resourcesPapeletas = (type,data={},id=null) => typeResource(url.RESOURCE_PAPELETAS,type,data,id)
//reports
export const resourcesReports = (type,data={},id=null) => typeResource(url.RESOURCE_REPORTS,type,data,id)
//ventas de combustible
export const resourcesFuelSales = (type,data={},id=null) => typeResource(url.RESOURCE_FUEL_SALES,type,data,id)
//ARchivos
export const resourcesFiles = (type,data={},id=null) => typeResource(url.RESOURCE_FILES,type,data,id)
export const resourcesOtrosEgresos = (type,data={},id=null) => typeResource(url.RESOURCE_OTROS_EGRESOS,type,data,id)

// Files image
export const postImage = (formData, config) => postFile1(url.RESOURCE_FILES_IMAGE, formData, {
    ...config,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
})

//Dashboard
export const getDashboardSalidas = (id, config) => get(`${url.DASHBOARD_SALIDAS}/${id}`, config)


const typeResource = (route,type,data={},id=null) => {
    switch (type) {
    case 'get':
        return get(route,data);
        break
    case 'show':
        return get(`${route}/${id}`,data);
        break
    case 'post':
        return post(`${route}`,data);
        break
    case 'put':
        return put(`${route}/${id}`,data);
        break
    case 'del':
        return del(`${route}/${id}`);
        break
    case 'postFile':
        return postFile(`${route}`,data);
        break
    }

}


export {
    getLoggedInUser,
    isUserAuthenticated,
  //postFakeRegister,
  //postFakeProfile,
  //postFakeForgetPwd,
  //postJwtRegister,
  //postJwtLogin,
  //postJwtForgetPwd,
  //postJwtProfile
}
