import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";

import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

const LineChart = ({
  datasets = [],
  yMax = null,
  xMin = null,
  xMax = null,
  title = "",
  yTitle = "",
  unit = "",
  stepSize = "",
}) => {
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      title: {
        display: true,
        text: title,
      },
    },
    hover: {
      mode: "index",
      intersec: false,
    },
    scales: {
      x: {
        type: "time",
        time: {
          tooltipFormat: "YYYY-MM-DD HH:mm",
          unit,
        },
        title: {
          display: false,
          text: "Time",
        },
        ticks: {
          stepSize,
        },
        ...(xMin ? { min: xMin } : {}),
        ...(xMax ? { max: xMax } : {}),
      },
      y: {
        title: {
          display: true,
          text: yTitle,
        },
        min: 0,
        ...(yMax ? { max: Number(yMax) } : {}),
        ticks: {
          //stepSize,
        },
      },
    },
  };

  const data = {
    datasets,
  };

  return <Line options={options} data={data} />;
};

export default LineChart;
