import React, { useEffect, useState } from "react";

import { deleteUser, getUsers } from "../../../helpers/backend_helper";

const initFilters = {
  search: "",
  page: 1,
  perPage: 15,
};

const initModal = {
  edit: false,
  delete: false,
  password: false,
  hosts: false,
};

const useUsers = () => {
  const [item, setItem] = useState();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(initModal);
  const [filters, setFilters] = useState(initFilters);
  const [metadata, setMetadata] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  const onAdd = () => {
    setItem(null);
    setModal((prev) => ({ ...prev, edit: true }));
  };

  const onEdit = (user) => {
    setItem(user);
    setModal((prev) => ({ ...prev, edit: true }));
  };

  const onDelete = (user) => {
    setItem(user);
    setModal((prev) => ({ ...prev, delete: true }));
  };
  
  const onUpdatePassword = (user) => {
    setItem(user);
    setModal((prev) => ({ ...prev, password: true }));
  };
  
  const onAssignHosts = (user) => {
    setItem(user);
    setModal((prev) => ({ ...prev, hosts: true }));
  };

  const onConfirmDelete = () => {
    setIsDeleting(true);
    deleteUser(item.id)
      .then(() => {
        fetchUsers();
        setModal((prev) => ({ ...prev, delete: false }));
      })
      .finally(() => setIsDeleting(false));
  };

  const fetchUsers = () => {
    const controller = new AbortController();

    const config = {
      params: filters,
      signal: controller.signal,
    };

    setIsLoading(true);
    getUsers(config)
      .then((resp) => {
        setData(resp.data);
        setMetadata(resp.metadata);
      })
      .finally(() => setIsLoading(false));

    return () => {
      controller.abort();
    };
  };

  useEffect(fetchUsers, [filters]);

  return {
    state: {
      data,
      modal,
      item,
      filters,
      metadata,
      isLoading,
      isDeleting,
    },
    setters: {
      setModal,
      setFilters,
    },
    methods: {
      onAdd,
      onEdit,
      onDelete,
      fetchUsers,
      onAssignHosts,
      onConfirmDelete,
      onUpdatePassword,
    },
  };
};

export default useUsers;
