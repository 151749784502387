//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"
export const POST_LOGIN_TOKEN = "/login"
export const RESET_PASSW = "/resetPasword"
export const CHANGE_PASSW = "/changePasword"
export const SEND_CONFIRMATION = "/verify"

export const ME = "/me"

//Users Resource (Store, Update, Show, Index)
export const RESOURCE_USERS = "/users"
export const FILE_PROFILE_USER = "/user/file"
export const GET_PROFILES = "/user/profiles"
export const ACTIVE_USER = "/user/active"
export const USERS_PROFILE = "/user/show/profile"
export const CHANGE_PASSWORD_USER = "/user/change/password"

//Configuration Resource
export const RESOURCE_CONFIGURATIONS = "/configurations"
export const SELECTS_CONFIGURATIONS= "/configuration/selects"
export const GET_ACTIVE_CONFIGURATION = "/configuration/showActive"

//Company Resource
export const RESOURCE_COMPANIES = "/companies"

//Plans Resource
export const RESOURCE_PLANS = "/catalogs/plans"

//PROFILES -PERMISSIONS
export const RESOURCE_PROFILES = "/profiles"
export const RESOURCE_PERMISSIONS = "/permissions"
export const PERMISSIONS_SELECT = "/permission/selects"

/**CATALOGOS DE SISTEMA**/
//routes
export const RESOURCE_ROUTE = "/catalogs/route"
export const GET_ROUTE_SELECTS = "/catalogs/getSelects"
export const ACTIVE_ROUTES = "/catalogs/routes/active"
//units
export const RESOURCE_UNIT = "/catalogs/unit"
export const GET_USERS_SELECTS = "/catalogs/unitsUsers"
//RAMALES
export const RESOURCE_BRANCH = "/catalogs/branch"
export const ACTIVE_BRANCH = "/catalogs/branches/active"
export const DUPLICATE_BRANCH = "/catalogs/branches/duplicate"
export const ASSOCIATE_BRANCH_UNITS = "/catalogs/branches/associate-branch-units"
//GEOCERCAS
export const RESOURCE_GEOFENCE = "/catalogs/geofence"
export const RESOURCE_BRANCH_GEO = "/branch_geo"
//configuracion de rutas
export const RESOURCE_CONFIGURATION_ROUTE = "/route/config"
export const ACTIVE_CONFIGURATION_ROUTE = "/routes/config/active"
export const ACTIVE_LOCK_CONFIGURATION_ROUTE = "/routes/config/activeLock"
//OPERATORES
export const RESOURCE_OPERATORS = "/catalogs/operator"
export const SAVE_OBSERVATION = "/catalogs/operators/observation"
export const GET_OPERATORS_UNITS = "/catalogs/operators/units"
//salidas
export const RESOURCE_SALIDAS = "/salidas/resource"
export const RESOURCE_SALIDAS_CONFIGS = "/salidas/configs"
export const RETURN_STORE = "/salidas/returnStore";
export const GET_SALIDAS_TABLERO = "/tablero";
export const POST_PAGO_ADMINISTRATIVO = "/salidas/pagoAdministrativo"
export const BASE_SALIDAS = '/salidas'
export const POST_SALIDAS_IMPORT_FILE = '/salidas/import'


// DEPOSITS
export const RESOURCE_DEPOSITS = "/operations/deposits"
// PAYROLL
export const RESOURCE_PAYROLL = "/operations/payroll"
//IMPREVISTOS
export const RESOURCE_IMPREVISTOS = "/operations/imprevistos"
export const GET_IMPREVISTOS_SELECTS = "/operations/imprevisto/selects"
//attendance
export const RESOURCE_ATTENDANCE = "/operations/attendances";
//cONCESIONARIO ARENDENCE
export const RESOURCE_ATTENDANCE_CONCESIONARIO = "/operations/attendances-concesionario";
//PAPELETAS
export const RESOURCE_PAPELETAS = "/operations/papeletas"
//REPORTS
export const RESOURCE_REPORTS = "/reports"
//VENTAS DE COMBUSTIBE
export const RESOURCE_FUEL_SALES = "/operations/fuel_sales"
//ARCHIVOS
export const RESOURCE_FILES = "/files"
export const RESOURCE_FILES_IMAGE = "/files-image"
//Otros egresos
export const RESOURCE_OTROS_EGRESOS = "/operations/otrosEgresos"
//CONCESIONARIOS
export const RESOURCE_CONCESIONARIOS = "/catalogs/concesionario"
//DASHBOARD
export const DASHBOARD_SALIDAS = "dashboard/salidas"

//REPORTS
export const RESOURCE_SERVERS = "/servers"

