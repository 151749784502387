import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

import { postLoginToken } from "../../../helpers/backend_helper"
import { redirectUserToHome } from '../../../helpers/login';

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLoginToken, {
      email: user.email,
      password: user.password,
    })
    /* const response = {
      user: {
        name: 'Marco',
        email: 'marco@email.com'
      }
    }; */
    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response.user))
    redirectUserToHome(response.user, history)
  } catch (error) {
    //console.log(error.data)
    yield put(apiError('' + error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

// function* socialLogin({ payload: { data, history, type } }) {
//   try {
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const fireBaseBackend = getFirebaseBackend()
//       const response = yield call(
//         fireBaseBackend.socialLoginUser,
//         data,
//         type,
//       )
//       localStorage.setItem("authUser", JSON.stringify(response))
//       yield put(loginSuccess(response))
//     } else {
//       //const response = yield call(postSocialLogin, data)
//       localStorage.setItem("authUser", JSON.stringify(response))
//       yield put(loginSuccess(response))
//     }
//     history.push("/dashboard")
//   } catch (error) {
//     yield put(apiError(error))
//   }
// }

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  //yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
