import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { assignHostsToUser, getServers } from "../../../helpers/backend_helper";
import NibbleInput from "../../../components/Fields/NibbleInput";

const AssignHostsModal = ({ modal, setModal, item, reloadData }) => {
  const [hosts, setHosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      hosts: item?.hosts || [],
    },
    validationSchema: Yup.object({
      hosts: Yup.array(),
    }),
    onSubmit: async (values) => {
      save(values);
    },
  });

  const save = async (formValues) => {
    setLoading(true);

    try {
      await assignHostsToUser(item.id, formValues);
      setModal((prev) => ({ ...prev, hosts: false }));

      if (reloadData) {
        reloadData();
      }
    } catch (error) {
      console.log("error ", error);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    setModal((prev) => ({ ...prev, hosts: false }));
  };

  const fetchHosts = () => {
    getServers().then((resp) => {
      setHosts(resp.data);
    });
  };

  useEffect(fetchHosts, []);

  return (
    <div>
      <Modal isOpen={modal.hosts}>
        <ModalHeader className="bg-primary">
          <span className="text-light">Asignar servidores a usuario</span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="row">
              <NibbleInput
                md={12}
                label="Servidores"
                fieldName="hosts"
                fieldType="select-multiple"
                validation={validation}
                selectItems={hosts}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={loading}>
              {loading && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>{" "}
            <Button color="secondary" onClick={onCancel}>
              <i className="fa fa-times"></i> Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AssignHostsModal;
