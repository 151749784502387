import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { initModal } from "../useServer";
import { getServerLogs } from "../../../helpers/backend_helper";
import CustomDataTable from "../../../helpers/tables/CustomDataTable";

const columns = [
  {
    id: "usuario",
    name: "Usuario",
    selector: (row) => row.user,
  },
  {
    id: "comando",
    name: "Comando",
    selector: (row) => row.command_btn,
  },
  {
    id: "estatus",
    name: "Estatus",
    selector: (row) => row.status,
    center: true,
    cell: ({ status }) => (
      <span
        className={`${
          status === "completo" ? "bg-success" : "bg-danger"
        } font-size-10 badge`}
      >
        {status}
      </span>
    ),
  },
  {
    id: "fecha",
    name: "Fecha",
    selector: (row) => row.log_date,
    width: "180px",
    wrap: true,
  },
];

const LogsModal = ({ modal, setModal, item }) => {
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onCancel = () => {
    setModal(initModal);
  };

  const fetchLog = () => {
    setIsLoading(true);

    getServerLogs(item.id)
      .then((resp) => {
        setLogs(resp.data);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(fetchLog, []);

  return (
    <Modal isOpen={modal.logs}>
      <ModalHeader>{`${item?.name} Logs`}</ModalHeader>
      <ModalBody>
        <CustomDataTable
          items={logs}
          columns={columns}
          showSearch={false}
          pagination={false}
          pending={isLoading}
          onlyShowTable
        />
      </ModalBody>
      <ModalFooter>
        <div>
          <Button disabled={isLoading} onClick={onCancel}>
            Cerrar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default LogsModal;
