import React from "react";
import { getStorageUser } from "../../helpers/api_helper";

const ShowForRole = ({ roles, children }) => {
  const user = getStorageUser();

  if (!roles.includes(user?.role)) return null;
  return children;
};

export default ShowForRole;
