import React from "react";
import { Container } from "reactstrap";

import Columns from "./Columns";
import useUsers from "./useUsers";
import AddUpdModal from "./AddUpdModal";
import ConfirmDelete from "../../../components/Modal/ConfirmDelete";
import CustomDataTable from "../../../helpers/tables/CustomDataTable";
import UpdPasswordModal from "./UpdPasswordModal";
import AssignHostsModal from "./AssignHostsModal";

const Users = () => {
  const { state, setters, methods } = useUsers();
  
  const columns = Columns(
    methods.onDelete,
    methods.onEdit,
    methods.onUpdatePassword,
    methods.onAssignHosts
  );

  document.title = "Usuarios";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="col-12">
            <CustomDataTable
              tittle="Usuarios"
              items={state.data}
              columns={columns}
              filters={state.filters}
              pending={state.isLoading}
              metadata={state.metadata}
              setFilters={setters.setFilters}
              customTopHeader={
                <div className="row">
                  <div className="col-md-12 text-end">
                    <button
                      className="btn btn-sm btn-success"
                      onClick={methods.onAdd}
                    >
                      <i className="fas fa-solid fa-plus"></i> Agregar
                    </button>
                  </div>
                </div>
              }
            />
          </div>
        </Container>
      </div>
      {state.modal.delete && (
        <ConfirmDelete
          modal={state.modal}
          setModal={setters.setModal}
          onConfirm={methods.onConfirmDelete}
          isLoading={state.isDeleting}
        />
      )}
      {state.modal.edit && (
        <AddUpdModal
          item={state.item}
          modal={state.modal}
          setModal={setters.setModal}
          reloadData={methods.fetchUsers}
        />
      )}
      {state.modal.password && (
        <UpdPasswordModal
          item={state.item}
          modal={state.modal}
          setModal={setters.setModal}
          reloadData={methods.fetchUsers}
        />
      )}
      {state.modal.hosts && (
        <AssignHostsModal
          item={state.item}
          modal={state.modal}
          setModal={setters.setModal}
          reloadData={methods.fetchUsers}
        />
      )}
    </React.Fragment>
  );
};

export default Users;
