import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getStorageCompany, getStorageUser } from "../api_helper";
import "./DataTable.css";
import { InputGroup, InputGroupText, Input } from "reactstrap";

const CustomDataTable = ({
  tittle = null,
  columns,
  items,
  pending,
  filters,
  setFilters,
  metadata = {},
  showSearch = true,
  pagination = true,
  showTable = true,
  customTopHeader = null,
  customBottomHeader = null,
  onlyShowTable = false,
}) => {
  const [orderByData, setOrderByData] = useState(true);
  const [newItems, setNewItems] = useState(items);
  const [filterText, setFilterText] = useState(" ");
  let filterColumns = columns.filter((col) => {
    if (!col.button) {
      return col;
    }
  });
  filterColumns = filterColumns.map((col) => col.id);

  useEffect(() => {
    if (filterText != " ") {
      const search = () => {
        setFilters({ ...filters, search: filterText });
      };
      search();
    }
  }, [filterText]);

  useEffect(() => {
    const getCompanySession = () => {
      let company = getStorageCompany();
      let user = getStorageUser();
      if (company.id != user.company_id && user.profile_id == 1) {
        setFilters({ ...filters, companySession: company.id });
      }
    };
    getCompanySession();
  }, []);

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const handlePageChange = (page) => {
    setFilters({ ...filters, page: page });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setFilters({ ...filters, perPage: newPerPage, page: page });
  };

  const orderBy = async (cols) => {
    if (orderByData) {
      setOrderByData(false);
      setFilters({ ...filters, orderBy: cols.id, order: "asc" });
    } else {
      setOrderByData(true);
      setFilters({ ...filters, orderBy: cols.id, order: "desc" });
    }
  };

  return (
    <div className="">
      <div className={onlyShowTable ? '' : 'card'}>
        <div className="card-header">
          {tittle && <Breadcrumbs title={tittle} breadcrumbItem={tittle} />}
          {customTopHeader}
          {showSearch && (
            <div className="row">
              <div className="col-sm-2">
                <InputGroup>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Busqueda..."
                    aria-describedby="basic-addon2"
                    value={filters.search}
                    onChange={(e) =>
                      setFilters({ ...filters, search: e.target.value })
                    }
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        setFilters({ ...filters, enter: !filters.enter });
                      }
                    }}
                  />
                  <InputGroupText
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setFilters({
                        ...filters,
                        search: "",
                        enter: !filters.enter,
                      })
                    }
                  >
                    <i className="fa fa-times"></i>
                  </InputGroupText>
                </InputGroup>
              </div>
            </div>
          )}
          {customBottomHeader}
        </div>
        {showTable ? (
          <div className="card-body">
            <DataTable
              pagination={pagination}
              paginationPerPage={metadata?.per_page || 15}
              paginationRowsPerPageOptions={[15, 20, 50, 100]}
              columns={columns}
              data={items}
              paginationComponentOptions={paginationComponentOptions}
              progressPending={pending}
              progressComponent={
                <div>
                  <i className="fas fa-solid fa-spinner fa-spin"></i>{" "}
                  Cargando...
                </div>
              }
              noDataComponent={
                <div>
                  <i className="fas fa-solid fa-info text-primary"></i> No hay
                  registros para mostrar
                </div>
              }
              highlightOnHover={true}
              paginationServer
              paginationTotalRows={metadata?.total}
              paginationDefaultPage={1}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeaderWrap={false}
              onSort={(cols) => orderBy(cols)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomDataTable;
