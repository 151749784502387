import React, { useState } from "react";
import { Card, Col, FormFeedback, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const NibbleInputImage = ({
  md = 6,
  label = "label",
  fieldName = "field_name",
  itemFile = null,
  validation,
  postFileFunction = () => {},
}) => {
  const [newImage, setNewImage] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleAcceptedFiles = async (files) => {
    console.log(files[0]);
    setUploadingFile(true)
    try {
        const response = await postFileFunction(files[0]);
        setNewImage(response)
        validation.setFieldValue(fieldName,response.id,true)        
    } catch (error) {}

    setUploadingFile(false)
  }

  return (
    <Col md={md}>
        {itemFile && !newImage && (
        <div
          className={classNames({
            "pt-2": true,
            "mb-3": true,
          })}
        >
          <Label className="form-label">{label}</Label>
          <p>
            <img height="100" src={itemFile.url}></img>
          </p>
        </div>
      )}

      {newImage && (
        <div
          className={classNames({
            "pt-2": true,
            "mb-3": true,
          })}
        >
          <Label className="form-label">{label}</Label>
          <p>
            <img height="100" src={newImage.url}></img>
          </p>
          <button
            type="button"
            onClick={() => {
              setNewImage(null)
              validation.setFieldValue(fieldName, itemFile?.id ?? null )
            }}
            className="btn btn-primary btn-sm save-user mt-1"
          >
            <i className="fas fa-sync"></i> Cambiar
          </button>
        </div>
      )}
      { !newImage && 
      <div
        className={classNames({
          "d-none": false,
          "is-invalid":
            validation.touched[fieldName] && validation.errors[fieldName]
              ? true
              : false,
          "mt-3": true
        })}
      >
        <Label className="form-label">{label}</Label>
        <br />
        {!newImage &&
        <Dropzone
          onDrop={(acceptedFiles) => {
            handleAcceptedFiles(acceptedFiles);
          }}
          onFileDialogOpen={() => {
            validation.setFieldTouched(fieldName, true, true);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone" style={{ minHeight: "120px" }}>
              <div className="text-center needsclick mt-2" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mb-1">
                  <i className="display-5 text-muted bx bx-cloud-upload" />
                </div>
                <h5>Arrastra archivos aquí o da clic en cargar</h5>
              </div>
            </div>
          )}
        </Dropzone>
        }
      </div>
      }
      {validation.touched[fieldName] && validation.errors[fieldName] ? (
        <FormFeedback type="invalid" className="invalid">
          {validation.errors[fieldName]}
        </FormFeedback>
      ) : null}
    </Col>
  );
};

export default NibbleInputImage;
