import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";
//Import Icons
import FeatherIcon from "feather-icons-react";
//Import images
import giftBox from "../../assets/images/giftbox.png";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import { getPolicyUser, getStorageUser } from "../helpers/api_helper";

const PROFILES = {
  SuperAdmin: 1,
  Reseller: 2,
  Administrador: 3,
  Concesionario: 4,
  Checador: 5,
  Operador: 6,
  Admin: "admin",
  User: "user",
};

const PLANES = {
  Basico: 1,
  Avanzado: 2,
};

const SidebarContent = (props) => {
  const policy = getPolicyUser();
  const user = getStorageUser();
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Inicio </li>
            <li>
              <Link to="/dashboard" className="">
                <FeatherIcon icon="home" />
                <span>Inicio</span>
              </Link>
            </li>
            {[PROFILES.Admin].includes(user.role) && (
              <>
                <li className="menu-title">Catálogos </li>
                <li>
                  <Link to="/catalogs/users" className="">
                    <FeatherIcon icon="user" />
                    <span>Usuarios</span>
                  </Link>
                </li>
              </>
            )}
            <li className="menu-title">Administración </li>
            <li>
              <Link to="/servers" className="">
                <FeatherIcon icon="hard-drive" />
                <span>Servidores</span>
              </Link>
            </li>
            {[PROFILES.SuperAdmin].includes(user.profile_id) && (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <span className="fa fa-clipboard me-3"></span>
                    <span>{props.t("Reportes")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/reporte/facturacion-admin">
                        <span className="fas fa-building me-2"></span>
                        Facturación
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <FeatherIcon icon="grid" />
                    <span>{props.t("Catálogos")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/companies">
                        <span className="fas fa-building me-2"></span>
                        Empresas
                      </Link>
                    </li>

                    <li>
                      <Link to="/users" className="">
                        <FeatherIcon icon="users" />
                        <span>Usuarios</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/plans">
                        <span className="fas fa-calendar-check me-2"></span>
                        Planes
                      </Link>
                    </li>

                    <li>
                      <Link to="/profiles" className="">
                        <span className="fas fa-user-shield me-2"></span>
                        Perfiles
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}

            {[PROFILES.Reseller].includes(user.profile_id) && (
              <>
                <li className="">
                  <Link to="/#" className="has-arrow">
                    <span className="fa fa-clipboard me-3"></span>
                    <span>{props.t("Reportes")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/reporte/facturacion" className="">
                        <span className="fa fa-file-invoice me-3"></span>
                        <span>Facturación</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <FeatherIcon icon="grid" />
                    <span>{props.t("Catálogos")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/routes" className="">
                        <span className="fas fa-building me-2"></span>
                        Rutas
                      </Link>
                    </li>
                    <li>
                      <Link to="/users" className="">
                        <FeatherIcon icon="users" />
                        <span>Usuarios</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}

            {[PROFILES.Administrador].includes(user.profile_id) && (
              <>
                <li className="">
                  <Link to="/#" className="has-arrow">
                    <span className="fa fa-cogs me-3"></span>
                    <span>{props.t("Operaciones")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/salidas" className="">
                        <FeatherIcon icon="arrow-up-circle" />
                        <span>Salidas</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/papeletas" className="">
                        <FeatherIcon icon="list" />
                        <span>Papeleta</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/imprevistos" className="">
                        <FeatherIcon icon="zap-off" />
                        <span>Imprevistos</span>
                      </Link>
                    </li>

                    {user.route.plan_id == PLANES.Avanzado && (
                      <>
                        <li>
                          <Link to="/asistencia" className="">
                            <span className="fa fa-check me-3"></span>
                            <span>Asistencia Operadores</span>
                          </Link>
                        </li>
                        <li className="d-none">
                          <Link to="/asistencia/qrScan" className="">
                            <span className="fa fa-qrcode me-3"></span>
                            <span>Asistencia Qr</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/asistencia-concesionario" className="">
                            <span className="fa fa-check me-3"></span>
                            <span>Asistencia Concesionario</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/depositos" className="">
                            <span className="fas fa-coins me-3"></span>
                            <span>Depósitos</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/ventas_combustible" className="">
                            <span className="fas fa-gas-pump me-3"></span>
                            <span>Ventas de Combustible</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/egresos-otros" className="">
                            <span className="fas fa-money-bill me-3"></span>
                            <span>Otros Egresos</span>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
                <li className="">
                  <Link to="/#" className="has-arrow">
                    <span className="fa fa-clipboard me-3"></span>
                    <span>{props.t("Reportes")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/reporte/salidas" className="">
                        <FeatherIcon icon="arrow-up-circle" />
                        <span>Salidas</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/reporte/salidas-unidad" className="">
                        <FeatherIcon icon="arrow-up-circle" />
                        <span>Salidas / Unidad</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/reporte/excesos" className="">
                        <FeatherIcon icon="list" />
                        <span>Excesos</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/reporte/quemadas/operadores" className="">
                        <span className="fas fa-running me-3"></span>
                        <span>Quemadas / Operador</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/reporte/quemadas/unidades" className="">
                        <span className="fas fa-bus me-3"></span>
                        <span>Quemadas / Unidad</span>
                      </Link>
                    </li>

                    {user.route.plan_id == PLANES.Avanzado && (
                      <>
                        <li>
                          <Link to="/reporte/ingresos-unidades" className="">
                            <span className="fas fa-money-bill me-3"></span>
                            <span>Ingresos / Unidades</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/reporte/egresos-combustible" className="">
                            <span className="fas fa-gas-pump me-3"></span>
                            <span>Egresos Combustible</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/reporte/gasolina-unidad" className="">
                            <span className="fas fa-gas-pump me-3"></span>
                            <span>Combustible / Unidad</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/reporte/depositos" className="">
                            <span className="fa fa-cash-register me-3"></span>
                            <span>Depósitos</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/reporte/egresos-otros/1" className="">
                            <span className="fas fa-money-bill me-3"></span>
                            <span>Otros Egresos</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/reporte/asistencias-unidad" className="">
                            <span className="fa fa-check me-3"></span>
                            <span>Asistencias / Unidad</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/nominas" className="">
                            <span className="fa fa-address-card me-3"></span>
                            <span>Nóminas</span>
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/reporte/pagos/administrativos"
                            className=""
                          >
                            <span className="fas fa-coins me-3"></span>
                            <span>Pago administrativo</span>
                          </Link>
                        </li>
                      </>
                    )}
                    <li>
                      <Link to="/reporte/llegada-base" className="">
                        <span className="fas far fa-arrow-alt-circle-down me-3"></span>
                        <span>Reporte de enroladas</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/reporte/cumplimiento-veladas" className="">
                        <span className="fas fa-building me-3"></span>
                        <span>Cumplimiento veladas</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="">
                  <Link to="/#" className="has-arrow">
                    <span className="fa fa-tools me-2"></span>
                    <span>{props.t("Configuraciones")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/configuration-route">
                        <span className="fas fa-list me-2"></span>
                        <span>Generales</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/salidas/configurations" className="">
                        <FeatherIcon icon="map" />
                        <span>Programación de salidas</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <FeatherIcon icon="grid" />
                    <span>{props.t("Catálogos")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/users" className="">
                        <FeatherIcon icon="users" />
                        <span>Usuarios</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/units" className="">
                        <span className="fas fa-bus me-2"></span>
                        Unidades
                      </Link>
                    </li>

                    <li>
                      <Link to="/geocercas" className="">
                        <span className="fas fa-object-ungroup me-2"></span>
                        Geocercas
                      </Link>
                    </li>

                    <li>
                      <Link to="/ramales" className="">
                        <span className="fas fa-road me-2"></span>
                        Ramales
                      </Link>
                    </li>

                    <li>
                      <Link to="/operators" className="">
                        <span className="fas fa-user-tie me-2"></span>
                        Operadores
                      </Link>
                    </li>

                    <li>
                      <Link to="/concesionarios" className="">
                        <span className="fas fa-user-tie me-2"></span>
                        Concesionarios
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to={"/dashboard/" + user.route.slug}
                    target="_blank"
                    className=""
                  >
                    <span className="fa fa-chalkboard-teacher me-2"></span>
                    <span>Monitoreo Ruta</span>
                  </Link>
                </li>
              </>
            )}

            {[PROFILES.Checador].includes(user.profile_id) && (
              <>
                <li className="">
                  <Link to="/#" className="has-arrow">
                    <span className="fa fa-cogs me-3"></span>
                    <span>{props.t("Operaciones")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/salidas" className="">
                        <FeatherIcon icon="arrow-up-circle" />
                        <span>Salidas</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/papeletas" className="">
                        <FeatherIcon icon="list" />
                        <span>Papeleta</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/imprevistos" className="">
                        <FeatherIcon icon="zap-off" />
                        <span>Imprevistos</span>
                      </Link>
                    </li>

                    {user.route.plan_id == PLANES.Avanzado && (
                      <>
                        <li>
                          <Link to="/asistencia" className="">
                            <span className="fa fa-check me-3"></span>
                            <span>Asistencia Operadores</span>
                          </Link>
                        </li>
                        <li className="d-none">
                          <Link to="/asistencia/qrScan" className="">
                            <span className="fa fa-qrcode me-3"></span>
                            <span>Asistencia Qr</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/asistencia-concesionario" className="">
                            <span className="fa fa-check me-3"></span>
                            <span>Asistencia Concesionario</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/depositos" className="">
                            <span className="fas fa-coins me-3"></span>
                            <span>Depósitos</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/ventas_combustible" className="">
                            <span className="fas fa-gas-pump me-3"></span>
                            <span>Ventas de Combustible</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/egresos-otros" className="">
                            <span className="fas fa-money-bill me-3"></span>
                            <span>Otros Egresos</span>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
                <li className="">
                  <Link to="/#" className="has-arrow">
                    <span className="fa fa-clipboard me-3"></span>
                    <span>{props.t("Reportes")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/reporte/salidas" className="">
                        <FeatherIcon icon="arrow-up-circle" />
                        <span>Salidas</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/reporte/salidas-unidad" className="">
                        <FeatherIcon icon="arrow-up-circle" />
                        <span>Salidas / Unidad</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/reporte/excesos" className="">
                        <FeatherIcon icon="list" />
                        <span>Excesos</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/reporte/quemadas/operadores" className="">
                        <span className="fas fa-running me-3"></span>
                        <span>Quemadas / Operador</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/reporte/quemadas/unidades" className="">
                        <span className="fas fa-bus me-3"></span>
                        <span>Quemadas / Unidad</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/reporte/pagos/administrativos" className="">
                        <span className="fas fa-coins me-3"></span>
                        <span>Pago administrativo</span>
                      </Link>
                    </li>

                    {user.route.plan_id == PLANES.Avanzado && (
                      <>
                        <li>
                          <Link to="/reporte/asistencias-unidad" className="">
                            <span className="fa fa-check me-3"></span>
                            <span>Asistencias / Unidad</span>
                          </Link>
                        </li>
                      </>
                    )}

                    <li>
                      <Link to="/reporte/llegada-base" className="">
                        <span className="fas far fa-arrow-alt-circle-down me-3"></span>
                        <span>Reporte de enroladas</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/reporte/cumplimiento-veladas" className="">
                        <span className="fas fa-building me-3"></span>
                        <span>Cumplimiento veladas</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="">
                  <Link to="/#" className="has-arrow">
                    <span className="fa fa-tools me-2"></span>
                    <span>{props.t("Configuraciones")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/salidas/configurations" className="">
                        <FeatherIcon icon="map" />
                        <span>Programación de salidas</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <FeatherIcon icon="grid" />
                    <span>{props.t("Catálogos")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/operators" className="">
                        <span className="fas fa-user-tie me-2"></span>
                        Operadores
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to={"/dashboard/" + user.route.slug}
                    target="_blank"
                    className=""
                  >
                    <span className="fa fa-chalkboard-teacher me-2"></span>
                    <span>Monitoreo Ruta</span>
                  </Link>
                </li>
              </>
            )}

            {[PROFILES.Concesionario].includes(user.profile_id) && (
              <>
                <li className="">
                  <Link to="/#" className="has-arrow">
                    <span className="fa fa-cogs me-3"></span>
                    <span>{props.t("Operaciones")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/salidas" className="">
                        <FeatherIcon icon="arrow-up-circle" />
                        <span>Salidas</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to={"/dashboard/" + user.route.slug}
                    target="_blank"
                    className=""
                  >
                    <span className="fa fa-chalkboard-teacher me-2"></span>
                    <span>Monitoreo Ruta</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
