import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { initForm } from "./AddUpdModal";
import NibbleInput from "../../../components/Fields/NibbleInput";
import { putUser } from "../../../helpers/backend_helper";

const UpdPasswordModal = ({ modal, setModal, item, reloadData }) => {
  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...initForm, ...item },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      save(values);
    },
  });

  const save = async (formValues) => {
    setLoading(true);
    try {
      await putUser(formValues);
      setModal((prev) => ({ ...prev, password: false }));

      if (reloadData) {
        reloadData();
      }
    } catch (error) {
      console.log("error ", error);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    setModal((prev) => ({ ...prev, password: false }));
  };

  return (
    <div>
      <Modal isOpen={modal.password}>
        <ModalHeader className="bg-primary">
          <span className="text-light">Cambiar contraseña</span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="row">
              <NibbleInput
                md={12}
                label="Nueva contraseña"
                fieldName="password"
                fieldType="password"
                validation={validation}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={loading}>
              {loading && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>{" "}
            <Button color="secondary" onClick={onCancel}>
              <i className="fa fa-times"></i> Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdPasswordModal;
