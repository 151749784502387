export const redirectUserToHome = (user, history) => {
    //console.log(user)
    const userType= user.user?.type || user.type;
    const userProfile= user.profile_id;
    if (userProfile==6) {
        history.push(`users/profile/${user.id}`);
    }else{
        history.push(userType == 2 ? "/provider/daily": (userType == 3 ? "/client/daily": 'dashboard'));    
    }
    
}