import { 
    SET_ERRORS, RESET_ERRORS 
} from './actionTypes';

const InitialState = [];

export default function errors(state = InitialState, action) {
    switch (action.type) {
        case SET_ERRORS:
            return action.payload
        case RESET_ERRORS:
            return [];
        default:
            return state;
    }
}
