import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { loginUser } from "../../store/actions"
import { getMe } from "../../helpers/backend_helper"
// import images
import logo from "../../assets/images/logo-sm.svg"
//Import config
import CarouselPage from "../AuthenticationInner/CarouselPage"
//Configuracion
import { getConfiguration } from '../../helpers/backend_helper';
//import { getStorageConfig } from '../../helpers/api_helper';
import {ToastContainerHelper} from '../../helpers/alerts/alertHelper';
const Login = props => {

  const dispatch = useDispatch()
  const [configuration, setConfiguration] = useState({image:'/images/gogotrack_logo.png',name:'GogoTrack',url:''})
  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))
  useEffect(() => {

  }, [])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Ingresa tu correo electronico"),
      password: Yup.string().required("Ingresa tu contraseña"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    }
  });

  document.title= "Iniciar sesión";
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="mx-auto col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                <ToastContainerHelper/>
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">¡Bienvenido !</h5>
                        <p className="text-muted mt-2">Introduce tus credenciales para ingresar.</p>
                      </div>
                     <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <div className="mb-3">
                          <Label className="form-label">Correo electrónico</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Correo electrónico"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Contraseña</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Contraseña"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            {/* <div className="form-check">
                              <Link to="/forgot-password" className="">
                                ¿Olvidaste tu contraseña?
                              </Link>
                            </div> */}

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Iniciar sesión
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="text-center">
                      <p className="mb-0">© {new Date().getFullYear()} Sistema de Monitoreo</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {/* <CarouselPage /> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}