import React from "react";
import { Container } from "reactstrap";

import useServer from "./useServer";
import Filters from "./components/filters";
import ServersGrid from "./components/servers-grid";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ConfirmResetModal from "./components/confirm-reset-modal";
import LogsModal from "./components/logs-modal";

const Server = () => {
  const { servers, isLoading, filters, setFilters, state, setters, methods } =
    useServer();

  document.title = "Servidores | Sistema de Monitoreo";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Servidores" breadcrumbItem="Servidores" />
          <Filters
            filters={filters}
            isLoading={isLoading}
            setFilters={setFilters}
          />
          <ServersGrid servers={servers} methods={methods} />
        </Container>
      </div>
      {(state.modal.reset || state.modal.forceReset) && (
        <ConfirmResetModal
          modal={state.modal}
          setModal={setters.setModal}
          item={state.item}
          reloadData={methods.fetchServers}
        />
      )}
      {state.modal.logs && (
        <LogsModal
          modal={state.modal}
          setModal={setters.setModal}
          item={state.item}
        />
      )}
    </React.Fragment>
  );
};

export default Server;
