import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ConfirmDelete = ({ modal, setModal, onConfirm, isLoading }) => {
  const onCancel = () => {
    setModal((prev) => ({ ...prev, delete: false }));
  };

  return (
    <Modal isOpen={modal.delete}>
      <ModalHeader>Eliminar registro</ModalHeader>
      <ModalBody>¿Esta seguro que desea eliminar el registro?</ModalBody>
      <ModalFooter>
        <div>
          <Button
            disabled={isLoading}
            color="danger"
            className="me-2"
            onClick={onConfirm}
          >
            Eliminar
          </Button>
          <Button disabled={isLoading} onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmDelete;
