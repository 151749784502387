import React from "react";
import { Row, Spinner } from "reactstrap";

const Filters = ({ filters, isLoading, setFilters }) => {
  const onChangeFilter = (e) => {
    setFilters((prev) => ({ ...prev, timeInterval: e.target.value }));
  };

  return (
    <Row>
      <div className="col-md-3">
        <div className="mb-3">
          <label className="form-Label form-label">
            Filtrar {isLoading ? <Spinner size="sm" /> : null}
          </label>
          <select
            value={filters.timeInterval}
            onChange={onChangeFilter}
            className="form-select"
          >
            <option value="last 1 hour">Última hora</option>
            <option value="last 6 hours">Últimas 6 horas</option>
            <option value="last day">Último día</option>
            <option value="last 7 days">Últimos 7 días</option>
          </select>
        </div>
      </div>
    </Row>
  );
};

export default Filters;
