import { useEffect, useState } from "react";

import { resourcesServers } from "../../helpers/backend_helper";

const initFilters = { timeInterval: "last 1 hour" };

export const initModal = {
  reset: false,
  forceReset: false,
  logs: false,
};

const useServer = () => {
  const [item, setItem] = useState();
  const [modal, setModal] = useState(initModal);
  const [servers, setServers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(initFilters);

  const onResetServer = (item) => {
    setItem(item);
    setModal((prev) => ({ ...prev, reset: true }));
  };

  const onForceResetServer = (item) => {
    setItem(item);
    setModal((prev) => ({ ...prev, forceReset: true }));
  };

  const onShowServerLogs = (item) => {
    setItem(item);
    setModal((prev) => ({ ...prev, logs: true }));
  };

  const fetchServers = () => {
    setIsLoading(true);
    resourcesServers(
      "get",
      {
        params: filters,
      },
      ""
    )
      .then((resp) => {
        setServers(resp);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(fetchServers, [filters]);

  return {
    servers,
    filters,
    isLoading,
    setFilters,
    state: {
      item,
      modal,
    },
    setters: {
      setModal,
    },
    methods: {
      fetchServers,
      onResetServer,
      onShowServerLogs,
      onForceResetServer,
    },
  };
};

export default useServer;
