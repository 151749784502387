import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
//import images
import logo from "../../assets/images/logo-sm.svg";
import CarouselPage from "../AuthenticationInner/CarouselPage"
import { useHistory } from "react-router-dom"
import { getConfiguration,sendConfirmation,changePassw } from '../../helpers/backend_helper';
import {ToastContainerHelper} from '../../helpers/alerts/alertHelper';

const PageRegister = () => {
    const [configuration, setConfiguration] = useState({image:'',name:''})
    const {id} = useParams();
    const {token} = useParams();
    const {reset} = useParams();
    const [data, setData] = useState({token:token,id:id,reset:reset,email:'',password:'',passConfirm:''})
    useEffect(() => {
      getConfiguration().then(response => {
        if(response.status){
          setConfiguration(response.configuration)  
        }
      })
  }, [])
    let history = useHistory()
    const save = () => {
        if(reset){
            changePassw(data).then(response => {
                if(response.status){
                    history.push(
                    {
                     pathname: '/login',
                 })
                }
            })
        }else{
            sendConfirmation(data).then(response => {
                if(response.status){
                    history.push({pathname: '/login',})
                }
            })
        }
    }
    //meta title
    document.title = reset ? "Recuperar Contraseña":"Confirmar Contraseña";
    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4} md={5} className="col-xxl-3">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <img src={configuration.url ? configuration.url:''} alt="" height="28" /> <span className="logo-txt">{configuration.name ? configuration.name:''}</span>
                                              </Link>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">{reset ? 'Recuperación':'Confirmación'} de contraseña</h5>
                                                <p className="text-muted mt-2">Confirma tus datos para continuar.</p>
                                            </div>
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Correo electrónico</label>
                                                    <input type="email" onChange={ e => setData({...data,email:e.target.value}) } className="form-control" id="email" placeholder="Enter email" required />
                                                    <div className="invalid-feedback">
                                                        Ingresa tu Correo electrónico
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="password" className="form-label">Contraseña</label>
                                                    <input type="password"  onChange={ e => setData({...data,password:e.target.value}) } className="form-control" id="password" placeholder="Enter password" required />
                                                    <div className="invalid-feedback">
                                                        Ingresa la contraseña
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="passConfirm" className="form-label">Confirmacion Contraseña</label>
                                                    <input type="password"  onChange={ e => setData({...data,passConfirm:e.target.value}) } className="form-control" id="passConfirm" placeholder="Enter password" required />
                                                    <div className="invalid-feedback">
                                                        Confirma tu contraseña
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <button onClick={save} className="btn btn-primary w-100 waves-effect waves-light">Registrar</button>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <CarouselPage />
                    </Row>
                </Container>
            </div>
            <ToastContainerHelper/>
        </React.Fragment>
    );
};

export default PageRegister;