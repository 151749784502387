import React from "react";

const Columns = (onDelete, onEdit, onUpdatePassword, onAssignHosts) => {
  const columns = [
    {
      id: "name",
      name: "Nombre",
      selector: (row) => row.full_name,
      //sortable: true,
      wrap: true,
    },
    {
      id: "email",
      name: "Correo",
      selector: (row) => row.email,
      //sortable: true,
      wrap: true,
    },
    {
      id: "email",
      name: "Servidores asignados",
      selector: (row) => row.hosts?.length,
      //sortable: true,
      center:true,
      wrap: true,
    },
    {
      id: "actions",
      name: "Acciones",
      width: "20%",
      button: true,
      cell: (row) => (
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <button
              className="btn btn-info btn-sm"
              title="Actualizar contraseña"
              onClick={() => onAssignHosts(row)}
            >
              <i className="fas fa-server"></i>
            </button>
          </div>
          <div className="p-2">
            <button
              className="btn btn-secondary btn-sm"
              title="Actualizar contraseña"
              onClick={() => onUpdatePassword(row)}
            >
              <i className="fas fa-key"></i>
            </button>
          </div>
          <div className="p-2">
            <button
              className="btn btn-warning btn-sm"
              title="Editar"
              onClick={() => onEdit(row)}
            >
              <i className="fas fa-edit"></i>
            </button>
          </div>
          <div className="p-2">
            <button
              className="btn btn-danger btn-sm"
              title="Eliminar"
              onClick={() => onDelete(row)}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        </div>
      ),
    },
  ];
  return columns;
};

export default Columns;
