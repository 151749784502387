import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import NibbleInput from "../../../components/Fields/NibbleInput";
import { postUser, putUser } from "../../../helpers/backend_helper";

export const initForm = {
  id: "",
  name: "",
  email: "",
  password: "",
  lastname1: "",
  lastname2: "",
};

const AddUpdModal = ({ modal, setModal, item, reloadData }) => {
  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...initForm, ...item },
    validationSchema: Yup.object({
      id: Yup.string().nullable(),
      name: Yup.string().nullable().required("Required"),
      lastname1: Yup.string().nullable().required("Required"),
      lastname2: Yup.string().nullable(),
      email: Yup.string().email().nullable().required("Required"),
      password: Yup.string().when("id", {
        is: (id) => !id,
        then: Yup.string()
          .min(6, "Password must be at least 6 characters")
          .required("Required"),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    }),
    onSubmit: async (values) => {
      save(values);
    },
  });

  const save = async (formValues) => {
    setLoading(true);
    try {
      await (item?.id ? putUser(formValues) : postUser(formValues));
      setModal((prev) => ({ ...prev, edit: false }));

      if (reloadData) {
        reloadData();
      }
    } catch (error) {
      console.log("error ", error);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    setModal((prev) => ({ ...prev, edit: false }));
  };

  return (
    <div>
      <Modal isOpen={modal.edit}>
        <ModalHeader className="bg-primary">
          <span className="text-light">
            {item?.id ? "Editar" : "Agregar"} Usuario
          </span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="row">
              <NibbleInput
                md={12}
                maxLength={50}
                label="Nombre"
                fieldName="name"
                fieldType="text"
                validation={validation}
              />
              <NibbleInput
                md={6}
                maxLength={50}
                label="Apellido paterno"
                fieldName="lastname1"
                fieldType="text"
                validation={validation}
              />
              <NibbleInput
                md={6}
                maxLength={50}
                label="Apellido materno"
                fieldName="lastname2"
                fieldType="text"
                validation={validation}
              />
              <NibbleInput
                md={12}
                label="Correo electrónico"
                fieldName="email"
                fieldType="text"
                validation={validation}
              />
              {item?.id ? null : (
                <NibbleInput
                  md={12}
                  label="Contraseña"
                  fieldName="password"
                  fieldType="password"
                  validation={validation}
                />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={loading}>
              {loading && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>{" "}
            <Button color="secondary" onClick={onCancel}>
              <i className="fa fa-times"></i> Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AddUpdModal;
