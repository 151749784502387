import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

//Utility
import PageMaintenance from "../pages/Utility/PageMaintenance";
import PagePricing from "../pages/Utility/PagePricing/index";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//AuthenticationInner related pages
import PageLogin from "../pages/AuthenticationInner/PageLogin";
import PageConfirm from "../pages/Authentication/PageRegister";

import Server from "../pages/Server/server";
import Users from "../pages/Catalogs/Users/Users";


const userRoutes = [ 
  //dashboard
  { path: "/dashboard", component: Dashboard },
  { path: "/servers", component: Server },
  { path: "/catalogs/users", component: Users },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  
];

const authRoutes = [
  //authencation page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },

  //AuthenticationInner pages
  { path: "/page-login", component: PageLogin },
  { path: "/verifyMail/:id/:token/:reset?", component: PageConfirm },

  //Utility page
  { path: "/pages-maintenance", component: PageMaintenance },
  { path: "/pages-404", component: Error404 },
  { path: "/pages-500", component: Error500 },
];

const error = { path: "*", component: Error404 }

export { userRoutes, authRoutes, error };
