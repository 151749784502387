import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

import {
  forceRestartServer,
  restartServer,
} from "../../../helpers/backend_helper";
import { initModal } from "../useServer";

const ConfirmResetModal = ({ modal, setModal, item, reloadData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onCancel = () => {
    setModal(initModal);
  };

  const onConfirm = async () => {
    setIsLoading(true);

    try {
      if (modal.reset) {
        await restartServer(item.id);
      } else {
        await forceRestartServer(item.id);
      }

      onCancel();
      reloadData && reloadData();
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={modal.reset || modal.forceReset}>
      <ModalHeader>{`${
        modal.reset ? "Reiniciar servidor" : "Forzar reinicio del servidor"
      }`}</ModalHeader>
      <ModalBody className="text-center">
        <p>{`¿Esta seguro que desea ${
          modal.reset ? "reiniciar" : "forzar"
        } el servidor?`}</p>
        {isLoading ? (
          <div className="py-2">
            <Spinner />
            <small className="d-block pt-2 text-small">
              Este proceso puede demorar unos segundos
              <i className="ms-2 fas fa-clock" />
            </small>
          </div>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <div>
          <Button
            disabled={isLoading}
            color="danger"
            className="me-2"
            onClick={onConfirm}
          >
            Reiniciar
          </Button>
          <Button disabled={isLoading} onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmResetModal;
